import Layout from "./layout";
import Seo from "./seo";
import Button from "./Button";
import React from "react";
import {navigate} from "gatsby";


const GenericPage = ({ title, children }) => {
  const goBack = async () => {
    await navigate("/")
  }

  return (
    <Layout>
      <Seo title={title} />
      <div className={"content-container pt-4 pb-16 tablet:py-14 tablet:pt-24 desktop:py-14"}>
        <div className={"grid grid-cols-1 desktop:grid-cols-[0.25fr_0.75fr]"}>
          <div className={"relative tablet:absolute tablet:top-8 desktop:relative desktop:top-0"}>
            <Button onClick={goBack} text={"Go Home"} iconPrefix flipIcon />
          </div>

          {/*Content*/}
          <div className={"ml-0 mt-6 tablet:mt-0 tablet:ml-32 desktop:ml-0"}>
            {/*Header*/}
            <div className={"grid grid-cols-1 gap-y-3 desktop:flex desktop:gap-6 py-3"}>
              <div>
                {/*Title*/}
                <h1 className={"text-2xl leading-[1.1] text-secondary font-bold mb-4"}>
                  {title}
                </h1>
              </div>
            </div>

            {/*Content*/}
            {children}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GenericPage;