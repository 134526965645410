import React from "react";

const GenericPageHeading = ({ children }) => {
  return (
    <h2 className={"text-xl font-semibold text-secondary mt-4"}>
      {children}
    </h2>
  );
}

export default GenericPageHeading;