import React from "react";
import GenericPage from "../components/GenericPage";
import GenericPageHeading from "../components/GenericPageHeading";
import GenericPageParagraph from "../components/GenericPageParagraph";


const CookiesPolicy = () => {
  return (
    <GenericPage title={"Cookies Policy"}>
      <div className={"mt-6 font-medium flex flex-col gap-4"}>
        <GenericPageHeading>1. Introduction</GenericPageHeading>
        <GenericPageParagraph>
          Bauvill Group Limited ("we," "our," "us") uses cookies and similar tracking technologies to enhance
          your experience on our website. This Cookie Policy explains what cookies are, how we use them, and your
          choices regarding cookies.
        </GenericPageParagraph>

        <GenericPageHeading>2. What are Cookies?</GenericPageHeading>
        <GenericPageParagraph>
          Cookies are small text files that are placed on your device by websites that you visit. They are
          widely used to make websites work more efficiently, as well as to provide information to the site
          owners.
        </GenericPageParagraph>

        <GenericPageHeading>3. Types of Cookies We Use</GenericPageHeading>
        <GenericPageParagraph>
          <strong>Analytics Cookies:</strong> We use Google Analytics to collect information about how visitors use our
          website. These cookies help us understand which pages are visited, how long visitors stay on each page, and
          other useful statistics. This information is used to improve the website's functionality and user experience.

          We do not use any other types of cookies, such as functional or performance cookies, and we do not use
          advertising cookies.
        </GenericPageParagraph>

        <GenericPageHeading>4. Managing and Disabling Cookies</GenericPageHeading>
        <GenericPageParagraph>
          You can manage or disable cookies through the cookie banner displayed on our website or by adjusting your
          browser settings. Most web browsers allow you to control cookies through their settings preferences. Please
          refer to your browser's help section for more information on how to delete or disable cookies.
        </GenericPageParagraph>

        <GenericPageHeading>5. Duration of Cookies</GenericPageHeading>
        <GenericPageParagraph>
          Cookies set by our website are stored on your device for a standard duration as specified by Google Analytics.
          If you need specific details on cookie duration, you may refer to the Google Analytics Cookie Usage on
          Websites page.
        </GenericPageParagraph>

        <GenericPageHeading>6. Consent</GenericPageHeading>
        <GenericPageParagraph>
          We obtain user consent for cookies through an explicit consent banner displayed when you first visit our
          website. By continuing to use our website, you agree to the use of cookies as described in this policy.
        </GenericPageParagraph>

        <GenericPageHeading>7. Third-Party Cookies</GenericPageHeading>
        <GenericPageParagraph>
          We do not use any third-party cookies other than Google Analytics. Google Analytics may set cookies on your
          device when you visit our website. For more information on how Google uses cookies, please visit the Google
          Privacy & Terms page.
        </GenericPageParagraph>

        <GenericPageHeading>8. Changes to the Cookie Policy</GenericPageHeading>
        <GenericPageParagraph>
          We may update this Cookie Policy from time to time. Any changes will be posted on this page. If the changes
          are significant, we will provide a more prominent notice on our website.
        </GenericPageParagraph>

        <hr className={"mt-4 border-secondary"}/>

        <GenericPageHeading>9. Contact Us</GenericPageHeading>
        <GenericPageParagraph>
          If you have any questions or concerns about this Cookie Policy, please contact us at:
        </GenericPageParagraph>

        <hr className={"mt-4 border-secondary"}/>

        <GenericPageParagraph>
          This Cookie Policy was last updated on 02/08/2024.
        </GenericPageParagraph>
      </div>
    </GenericPage>
  )
}

export default CookiesPolicy;