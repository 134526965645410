import React from "react";

const GenericPageParagraph = ({ children }) => {
  return (
    <p className={"text-base font-normal text-secondary"}>
      {children}
    </p>
  );
}

export default GenericPageParagraph;